<template>
	<b-container style="overflow-x: visible" class="border-bottom">
		<b-row class="a-section-title">
			<b-col>
				<h2 class="text-center">
					<span class="w-color-orange z-fancy-underline">
						{{ $t('Najnovšie') }}<svg-fancy-underline-icon /> 
						<img src="./img/latest.png" class="a-small-logo">
					</span> <span class="w-color-black">{{ $t('kurzy, ktoré sme si pre vás pripravili...') }}</span>
				</h2>
			</b-col>
		</b-row>
		<b-row>
			<w-loadable :items="latestCourses" />
			<z-cards-carousel v-if="latestCourses && latestCourses.length" :cards="latestCourses">
				<template v-slot:card="props">
					<z-path-card v-if="props.card.slug === 'prirucka-prezitia-na-divokom-webe'" :prirucka="true" :path="props.card" />
					<z-course-card v-else-if="props.card._model == 'course'" :course="props.card" />
					<z-path-card v-else :path="props.card" />
				</template>
			</z-cards-carousel>
		</b-row>
		<b-row class="mt-3">
			<b-col class="text-center">
				<b-button href="/kurzy" variant="primary" class="a-poppins-btn -medium -wide">Pozrieť všetky kurzy</b-button>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	props: {
		courses: {
			required: true
		}
	},

	components: {
		'z-cards-carousel': 					() => import('@/plugins/lib@_component/cards-carousel/z-cards-carousel.vue'),
		'svg-fancy-underline-icon': 	() => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline'),
		'z-path-card': 								() => import('@/plugins/lib@course/path/card/z-path-card.vue'),
	},

	computed: {
		latestCourses() {
			return this.courses && [ ...this.courses ].sort((a, b) => {
				let comparison = b.published_at.localeCompare(a.published_at)
				if (comparison != 0) {
					return comparison
				}

				return b.id - a.id
			}).slice(0, 8)
		}
	}
}
</script>

<style lang="sass" scoped>
.a-small-logo
	position: absolute
	height: 22px
	margin-top: -13px
	left: 90%
</style>